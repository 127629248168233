import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { CopyAll } from '@mui/icons-material'
import { sha256 } from 'js-sha256'

export default function CheckRtolRefId() {
  const [referenceId, setReferenceId] = useState("")
  const [transaction, setTransaction] = useState({ currentAccount: [], proposedRefund: [], refunded: [] })
  const [error, setError] = useState("")
  const context = useContext(AppContext)

  function getTableData(table) {
    let text = '';
    const rows = table.querySelectorAll('tr');

    // Iterate through rows and cells
    for (const row of rows) {
      const cells = row.querySelectorAll('th, td');
      for (const cell of cells) {
        text += cell.textContent.trim() + '\t'; // Use tabs for separation
      }
      text += '\n'; // Add newline after each row
    }

    return text;
  }

  function copytable(el) {
    const table = document.getElementById('table');
    const text = getTableData(table)
    navigator.clipboard.writeText(text).then(() => {
      alert('Table data copied to clipboard!');
    })
      .catch(err => {
        alert('Failed to copy table data');
        // Handle clipboard permission errors or other issues (optional)
      });
  }

  async function fetchCheckRtolRefId(event) {
    event.preventDefault()
    const timestamp = `${new Date().getTime()}`
    const headers = {
      "Authorization": "Basic ZGFzaDokJSREJEFTSA==",
      "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
      "x-timestamp": timestamp
    }
    try {
      setError("")
      setTransaction(null)
      let transactionResponse = null
      const url = `https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/track-current-account?referenceId=${referenceId}`
      transactionResponse = await axios.get(url, {
        headers
      })
      if (
        transactionResponse.data?.data?.currentAccount?.length == 0 &&
        transactionResponse.data?.data?.deleted?.length == 0 &&
        transactionResponse.data?.data?.proposedRefund?.length == 0 &&
        transactionResponse.data?.data?.refunded?.length == 0
      ) {
        const url = `https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/track-current-account?tx=${referenceId}`
        transactionResponse = await axios.get(url, {
          headers
        })
      }
      setTransaction(transactionResponse.data.data)
    } catch (error) {
      setError("Status : Transaksi Tidak ditemukan pada sistem")
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>Check RTOL Ref ID</h2>
      <div>
        <form onSubmit={fetchCheckRtolRefId}>
          <label>Reference ID</label>
          <div style={{ display: "flex" }}>
            <input
              className=''
              name="referenceId"
              type='text'
              placeholder='Input Reference Id'
              value={referenceId}
              onChange={(event) => setReferenceId(event.target.value)}
            />
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='button' onClick={e => {
              setTransaction(null)
              setReferenceId("")
            }}>Clear</button>
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <div style={{ marginTop: "40px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {error && (
          <p>{error}</p>
        )}
        {transaction && (
          <>
            <button onClick={copytable} style={{ marginBottom: "10px", display: "flex", justifyContent: "center", padding: "5px" }}>
              <CopyAll></CopyAll>
              Copy
            </button>
            <table id='table' style={{ backgroundColor: "#cecece", padding: "15px", width: "100%" }}>
              <thead></thead>
              <tbody>
                {/* Tidak Di Temukan */}
                {transaction.currentAccount.length < 1 && transaction.proposedRefund.length < 1 && transaction.refunded.length < 1 && (
                  <>
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "red", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Transaksi Tidak ditemukan pada sistem</span> </td>
                    </tr>
                  </>
                )}
                {/* SUKSES di TRANSFER */}
                {transaction.currentAccount[0]?.data?.attachment && transaction.proposedRefund.length < 1 && transaction.refunded.length < 1 && (
                  <>
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "green", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Sukses di Transfer</span> </td>
                    </tr>
                    <tr>
                      <td>Tanggal di transfer</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.data.req.transactionDate}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.data.req.beneficiaryAccountNo}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.data.req.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>Jumlah Transfer</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.data.res.amount.value}</td>
                    </tr>
                    <tr>
                      <td>Reference No</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.TX}</td>
                    </tr>
                    <tr>
                      <td>Serial Transaksi</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.data.res.referenceNo}</td>
                    </tr>
                  </>
                )}
                {transaction.refunded.length > 0 && (
                  <>
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "green", color: "white", padding: "3px 10px", borderRadius: "20px" }}>Refunded</span> </td>
                    </tr>
                    <tr>
                      <td>Tanggal di transfer</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.metadata?.createdDate}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.data?.attachment?.responseInquiry.beneficiaryAccountNo}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.data?.attachment?.responseInquiry.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>Jumlah Transfer</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.data?.credit}</td>
                    </tr>
                    <tr>
                      <td>Reference No</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.data?.ID}</td>
                    </tr>
                    <tr>
                      <td>Serial Transaksi</td>
                      <td>: </td>
                      <td>{transaction.refunded[0]?.data?.attachment?.data?.res?.referenceNo}</td>
                    </tr>
                  </>
                )}
                {transaction.proposedRefund.length > 0 && (
                  <>
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}>Sedang diproses</span> </td>
                    </tr>
                    <tr>
                      <td>Tanggal di transfer</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.metadata?.createdDate ? new Date(transaction.proposedRefund[0]?.metadata?.createdDate).toLocaleDateString('id-ID', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',                        
                          hour: 'numeric',                        
                          minute: 'numeric',                        
                      }) : ""}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.data?.attachment?.responseInquiry.beneficiaryAccountNo}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.data?.attachment?.responseInquiry.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>Jumlah Transfer</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.data?.credit}</td>
                    </tr>
                    <tr>
                      <td>Reference No</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.data?.ID}</td>
                    </tr>
                    <tr>
                      <td>Serial Transaksi</td>
                      <td>: </td>
                      <td>{transaction.proposedRefund[0]?.data?.attachment?.responseInquiry?.partnerReferenceNo}</td>
                    </tr>
                  </>
                )}

              </tbody>
              <tfoot></tfoot>
            </table>
          </>
        )}


      </div>
    </div>
  )
}
